<template>
  <div class="question">
    <span v-if="$scopedSlots.question"><span class="question-title"><slot name="question"></slot></span></span>
    <span v-if="tooltip" class="question-title" v-tooltip.hover="tooltip">{{ question }}</span>
    <span v-else class="question-title">{{ question }}</span>

    <fieldset class="radio-set">
      <template v-for="option in options">
        <label :key="option.value">
          <input v-if="edit" type="radio" :name="name" :value="option.value" :checked="option.value === surveyData[name]" @change="setRadio(name, $event)">
          <span v-else class="fake-input fake-input-radio">
            <img v-if="option.value === surveyData[name]" :src="require('@/assets/images/response/checked.png')">
          </span>
          <span v-if="option.tooltip" v-tooltip.hover="option.tooltip">{{ option.label }}</span>
          <span v-else>{{ option.label }}</span>
        </label>
        <div class="extra ml-5" v-if="option.extra && option.value === surveyData[name]" :key="'extra_' + option.value">
          <label>
            Specificare:
            <input v-if="edit" type="text" :value="surveyData[option.extra]" @input="setText(option.extra, $event)">
            <span v-else class="fake-input fake-input-number ml-4">{{ surveyData[option.extra] }}</span>
          </label>
        </div>
      </template>
    </fieldset>
  </div>
</template>

<script>

import surveyMixin from '@/mixins/surveyMixin.js';

export default {
  mixins: [surveyMixin],
  props: {
    question: {
      type: String,
      required: false,
    },
    tooltip: {
      type: String,
      required: false,
    },
    name: {
      type: String,
      required: true,
    },
    options: {
      type: Array,
      required: true,
    },
    edit: {
      type: Boolean,
      default: true,
    },
  },
};

</script>
