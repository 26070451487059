<template>
  <div class="number-row">
    <div class="number-row-question">
      <span v-if="tooltip" :class="{ 'question-title': strong }" v-tooltip="tooltip">{{ question }}</span>
      <span v-else :class="{ 'question-title': strong }">{{ question }}</span>
    </div>
    <div class="number-row-answer">
      <div class="number-row-answer-male">
        <label>
          <span>M</span>
          <input v-if="edit" type="number" :min="0" :value="surveyData[name + '_M']" @input="setTextAsInteger(name + '_M', $event)" required>
          <span v-else class="fake-input fake-input-number">{{ surveyData[name + '_M'] }}</span>
        </label>
      </div>
      <div class="number-row-answer-female">
        <label>
          <span>F</span>
          <input v-if="edit" type="number" :min="0" :value="surveyData[name + '_F']" @input="setTextAsInteger(name + '_F', $event)" required>
          <span v-else class="fake-input fake-input-number">{{ surveyData[name + '_F'] }}</span>
        </label>
      </div>
    </div>
  </div>
</template>

<script>

import surveyMixin from '@/mixins/surveyMixin.js';

export default {
  mixins: [surveyMixin],
  props: {
    question: {
      type: String,
      required: true,
    },
    strong: {
      type: Boolean,
      default: true,
    },
    tooltip: {
      type: String,
      required: false,
    },
    name: {
      type: String,
      required: true,
    },
    edit: {
      type: Boolean,
      default: true,
    },
  },
};

</script>
