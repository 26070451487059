<template>
  <div>
    <div class="question">
      <span class="question-title">Indicare il numero e il sesso dei vostri ASPP e preposti/e</span>
      <QuestionNumberRow
        question="ASPP"
        name="education_nb_rspp"
        :strong="false"
        :edit="edit"
      />
      <QuestionNumberRow
        question="Preposti/e"
        name="education_nb_safety_responsibles"
        :strong="false"
        :edit="edit"
      />
    </div>

    <QuestionRadio :edit="edit" name="education_rls_training_gender_weight" :options="[
      { value: 1, label: 'Sì, solo per quanto riguarda gli aspetti legati alla gravidanza' },
      { value: 2, label: 'Sì, anche per altri aspetti inerenti le differenze di sesso e genere' },
      { value: 0, label: 'No' },
    ]">
      <template #question>* Nell'ambito delle attività informative/formative previste per i/le vostri/e rspp, rls o preposti/e sono stati trattati argomenti inerenti le differenze di <span data-explained-by-term @click="showTerm('sesso')">sesso</span> e <span data-explained-by-term @click="showTerm('genere')">genere</span>?</template>
    </QuestionRadio>

    <QuestionRadio :edit="edit" name="education_worker_training_gender_weight" :options="[
      { value: 1, label: 'Sì, solo per quanto riguarda gli aspetti legati alla gravidanza' },
      { value: 2, label: 'Sì, anche per altri aspetti inerenti le differenze di sesso e genere' },
      { value: 0, label: 'No' },
    ]">
      <template #question>* Nell'ambito delle attività informative/formative previste per i lavoratori e le lavoratrici avete inserito argomenti inerenti le differenze di <span data-explained-by-term @click="showTerm('sesso')">sesso</span> e <span data-explained-by-term @click="showTerm('genere')">genere</span>?</template>
    </QuestionRadio>

    <QuestionRadio :edit="edit" name="education_is_rls_election_date_agreed" :options="[
      { value: 1, label: 'Sì' },
      { value: 2, label: 'No, perché gli/le RLS sono di nomina RSU, o sono esclusivamente RLS territoriali' },
      { value: 0, label: 'No' },
    ]">
      <template #question>* La programmazione della riunione per l'elezione degli/lle RLS è stata concordata con i lavoratori e le lavoratrici per facilitare una equa partecipazione di tutti e tutte?</template>
    </QuestionRadio>

    <QuestionRadio :edit="edit" name="education_did_rls_asked_for_safety_and_gender" :options="[
      { value: 1, label: 'Sì e le tematiche sono state affrontate nei corsi di formazione' },
      { value: 3, label: 'Sì e sono in programma corsi di formazione inerenti tali tematiche' },
      { value: 2, label: 'Sì ma il DL non intende affrontare tali tematiche nei corsi di formazione' },
      { value: 0, label: 'No' },
    ]">
      <template #question>* Gli/le RLS hanno richiesto di affrontare tematiche di sicurezza e <span data-explained-by-term @click="showTerm('genere')">genere</span>?</template>
    </QuestionRadio>

    <QuestionRadio v-if="isUniversita || isAziendaSanitaria" :edit="edit" name="gender_related_formation_technical" :options="[
      { value: 1, label: 'Sì' },
      { value: 0, label: 'No' },
      { value: 2, label: 'Altro', extra: 'gender_related_studies_other_what' },
    ]">
      <template #question>* Sono previste attività formative/informative sulle tematiche di genere per il personale<template v-if="isUniversita"> tecnico amministrativo</template>?</template>
    </QuestionRadio>

    <QuestionCheckbox v-if="isUniversita || isAziendaSanitaria" :edit="edit" name="gender_related_formation_technical_what" :options="[
      { value: 1, label: 'Brochure' },
      { value: 2, label: 'Video' },
      { value: 'other', label: 'Altro', extra: 'what' },
    ]">
      <template #question>* E’ prevista un'attività di divulgazione sulle tematiche di genere e la condivisione alla genitorialità per il personale<template v-if="isUniversita"> tecnico amministrativo</template>?</template>
    </QuestionCheckbox>

    <QuestionCheckbox v-if="isAziendaSanitaria" :edit="edit" name="gender_related_formation_gep" :options="[
      { value: 1, label: 'Sì' },
      { value: 2, label: 'No' },
      { value: 3, label: 'No, ma l\'amministrazione sta predisponendo il documento' },
    ]">
      <template #question>* E’ stato adottato il Gender Equality Plan?</template>
    </QuestionCheckbox>

    <QuestionCheckbox v-if="isAziendaSanitaria" :edit="edit" name="gender_related_formation_alias" :options="[
      { value: 1, label: 'Sì' },
      { value: 2, label: 'No' },
      { value: 3, label: 'No, ma l\'amministrazione sta predisponendo il documento' },
    ]">
      <template #question>* E’ stato adottato un regolamento aziendale in tema di Alias?</template>
    </QuestionCheckbox>

    <InfoModal v-if="$modals.terms" modal-class="term-modal" :title="term" @close="closeTerm">
      <div v-html="termText"></div>
    </InfoModal>
    <InfoModal v-if="$modals.laws" modal-class="law-modal" :title="law" @close="closeLaw">
      <h2>{{ lawTitle }}</h2>
      <div v-html="lawText"></div>
    </InfoModal>
  </div>
</template>

<script>

import surveyMixin from '@/mixins/surveyMixin.js';
import stepTitleFilter from '@/filters/stepTitleFilter.js';

import QuestionNumberRow from '@/views/components/QuestionNumberRow.vue';
import QuestionRadio from '@/views/components/QuestionRadio.vue';
import QuestionCheckbox from '@/views/components/QuestionCheckbox.vue';
import InfoModal from '@/libs/Modals/components/InfoModal.vue';

export default {
  mixins: [surveyMixin],
  filters: {
    stepTitle: stepTitleFilter,
  },
  components: {
    QuestionNumberRow,
    QuestionRadio,
    QuestionCheckbox,
    InfoModal,
  },
  props: {
    edit: {
      type: Boolean,
      default: true,
    },
  },
  data () {
    return {};
  },
};

</script>
