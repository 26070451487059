<template>
  <div class="section-stepper row">
    <div v-if="null !== prevStep" class="col mb-4">
      <button type="button" class="btn btn-primary" @click.prevent="goToStep(prevStep)">
        {{ prevStep | stepTitle }}
      </button>
    </div>
    <div v-else class="col"></div>

    <div class="col text-right mb-4">
      <slot name="next-step-content" :goToStep="goToStep" :nextStep="nextStep" :enableNextStep="enableNextStep">
        <button type="button" class="btn btn-success" @click.prevent="goToStep(nextStep)" :disabled="!enableNextStep">
          {{ nextStep | stepTitle }}
        </button>
      </slot>
    </div>
  </div>
</template>

<script>

import goToStepMixin from '@/mixins/goToStepMixin.js';
import stepTitleFilter from '@/filters/stepTitleFilter.js';

export default {
  mixins: [goToStepMixin],
  filters: {
    stepTitle: stepTitleFilter,
  },
  props: {
    step: {
      type: Number,
      default: 1,
    },
    enableNextStep: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    prevStep () {
      const prevStep = this.step - 1;

      return prevStep < 1 ? null : prevStep;
    },
    nextStep () {
      return this.step + 1;
    },
  },
};

</script>
