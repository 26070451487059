export const steps = [
  {
    step: 1,
    title: 'Dati anagrafici Azienda/Ente e Organismi di parità/garanzia',
  },
  {
    step: 2,
    title: 'Distribuzione anagrafica e condizione occupazionale delle lavoratrici e dei lavoratori',
  },
  {
    step: 3,
    title: 'Salute e sicurezza',
  },
  {
    step: 4,
    title: 'Informazione, formazione e partecipazione',
  },
  {
    step: 5,
    title: 'Conciliazione lavoro-famiglia',
  },
];

export const getStep = stepNumber => {
  const step = steps.find(s => s.step === stepNumber);
  if (!step) {
    return null;
  }

  return step;
};

export const formatStepTitle = stepNumber => {
  const step = getStep(stepNumber);
  if (step === null) {
    return '';
  }

  return `${step.step}. ${step.title}`;
};
