<template>
  <BModal visible centered :hide-footer="true" :dialog-class="modalClasses" @cancel="close" @close="close" @hide="close">
    <template #modal-title>
      <slot name="title">{{ title }}</slot>
    </template>
    <template #modal-header-close>
      <i class="fas fa-times"></i>
    </template>

    <slot></slot>
  </BModal>
</template>

<script>

import { BModal } from 'bootstrap-vue';

export default {
  components: {
    BModal,
  },
  props: {
    title: {
      type: String,
      default: null,
    },
    modalClass: {
      type: String,
      default: null,
    },
  },
  computed: {
    modalClasses () {
      let modalClasses = 'modal--type-info';

      if (this.modalClass !== null) {
        modalClasses += ' ' + this.modalClass;
      }

      return modalClasses;
    },
  },
  methods: {
    close () {
      this.$emit('close');
    },
  },
};

</script>

<style lang="scss">

.modal-header {
  .close {
    background: none;
    border: none;
    box-shadow: none;
  }
}

</style>
